@tailwind base;
@tailwind components;
@tailwind utilities;
.no-scrollbar {
    -ms-overflow-style: none;  /* IE 10+ */
    scrollbar-width: none;     /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;             /* Chrome, Safari, Opera */
}